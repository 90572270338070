<div class="content" [ngSwitch]="tabSelector">
  <mfc-documents *ngSwitchCase="ApplicationTabsEnum.Documents"
                 [documents]="documents.signature"
                 [approvalSettings]="application?.approvalSettings ?? []"
                 [applicationId]="application?.externalId ?? ''">
  </mfc-documents>
  <mfc-documents *ngSwitchCase="ApplicationTabsEnum.ReadyDocuments"
                 [documents]="documents.ready"
                 [applicationId]="application?.externalId ?? ''">
  </mfc-documents>
  <mfc-discipline-table
          [applicationId]="application?.externalId ?? ''"
          [editable]="editable"
          [tabIndex]="index"
          *ngSwitchCase="ApplicationTabsEnum.Discipline">
  </mfc-discipline-table>
  <mfc-comments *ngSwitchCase="ApplicationTabsEnum.Comments"></mfc-comments>
  <mfc-approval-list *ngSwitchCase="ApplicationTabsEnum.List"
                     [approvalSettings]="application?.approvalSettings ?? []">
  </mfc-approval-list>
  <mfc-dynamic *ngSwitchCase="ApplicationTabsEnum.Dynamic"
               [groups]="dynamicTab?.groupProperties"
               [index]="index"
               [isAdd]="!status"
               [editable]="editable">
  </mfc-dynamic>
</div>
