import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {
  DisciplineTable,
  DisciplineTableByCycleType,
  DisciplineTableBySemester
} from "../../../../models/mfc/applicationForm/discipline-table.model";
import {GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {EnableDisciplineTable} from "../../../../models/mfc/enums/enable-discipline-table.enum";
import {process, State} from "@progress/kendo-data-query";

@Component({
  selector: 'mfc-discipline-table',
  templateUrl: './discipline-table.component.html',
  styleUrls: ['./discipline-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisciplineTableComponent implements OnInit {
  @Input() editable = false;
  @Input() applicationId = '';
  @Input() tabIndex = 0;
  @Input() tableType = EnableDisciplineTable.BySemester;

  // TODO: Модели
  protected gridView: GridDataResult = {data: [], total: 0};

  protected disciplineTableByCycleType: DisciplineTableByCycleType[] = [
    {...new DisciplineTableByCycleType(), id: '1', name: 'Дисциплина 1', dictCycleType: 'Цикл 1', hours: 100, laborIntensity: 10},
    {...new DisciplineTableByCycleType(), id: '2', name: 'Дисциплина 2', dictCycleType: 'Цикл 1', hours: 60, laborIntensity: 15},
    {...new DisciplineTableByCycleType(), id: '3', name: 'Дисциплина 3', dictCycleType: 'Цикл 2', hours: 100, laborIntensity: 65},
    {...new DisciplineTableByCycleType(), id: '4', createdManually: true, name: 'Дисциплина 4', dictCycleType: 'Цикл 1', hours: 100, laborIntensity: 10},
    {...new DisciplineTableByCycleType(), id: '5', name: 'Дисциплина 5', dictCycleType: 'Цикл 1', hours: 60, laborIntensity: 15},
    {...new DisciplineTableByCycleType(), id: '6', createdManually: true, name: 'Дисциплина 6', dictCycleType: 'Цикл 2', hours: 100, laborIntensity: 65},
    {...new DisciplineTableByCycleType(), id: '7', name: 'Дисциплина 7', dictCycleType: 'Цикл 1', hours: 100, laborIntensity: 10},
    {...new DisciplineTableByCycleType(), id: '8', name: 'Дисциплина 8', dictCycleType: 'Цикл 1', hours: 60, laborIntensity: 15},
    {...new DisciplineTableByCycleType(), id: '9', createdManually: true, name: 'Дисциплина 9', dictCycleType: 'Цикл 2', hours: 100, laborIntensity: 65},
  ];
  protected disciplineTableBySemester: DisciplineTableBySemester[] = [
    {...new DisciplineTableByCycleType(), name: 'Дисциплина 1', semester: 1, hours: 100, laborIntensity: 10},
    {...new DisciplineTableByCycleType(), name: 'Дисциплина 2', semester: 1, hours: 60, laborIntensity: 15},
    {...new DisciplineTableByCycleType(), name: 'Дисциплина 3', semester: 1, hours: 100, laborIntensity: 65},
    {...new DisciplineTableByCycleType(), name: 'Дисциплина 4', semester: 1, hours: 100, laborIntensity: 10},
    {...new DisciplineTableByCycleType(), name: 'Дисциплина 5', semester: 2, hours: 60, laborIntensity: 15},
    {...new DisciplineTableByCycleType(), name: 'Дисциплина 6', semester: 2, hours: 100, laborIntensity: 65},
    {...new DisciplineTableByCycleType(), name: 'Дисциплина 7', semester: 2, hours: 100, laborIntensity: 10},
    {...new DisciplineTableByCycleType(), name: 'Дисциплина 8', semester: 2, hours: 60, laborIntensity: 15},
    {...new DisciplineTableByCycleType(), name: 'Дисциплина 9', semester: 2, hours: 100, laborIntensity: 65},
  ];
  // TODO: Модель
  protected semesters = [
    {id: 1, name: '1 семестр'},
    {id: 2, name: '2 семестр'},
    {id: 3, name: '3 семестр'}
  ];

  protected isSemester = [EnableDisciplineTable.BySemester, EnableDisciplineTable.BySemesterPractice].includes(this.tableType);

  protected skip = 0;
  protected state: State = {
    skip: 0,
    take: 13
  };

  constructor() {}

  ngOnInit() {
    this.getDisciplines();
  }

  protected pageChange(event: PageChangeEvent): void {
    this.state = event;
    this.getDisciplines();
  }

  private getDisciplines() {
    this.gridView = process(
      [...this.createGrouping(this.isSemester ? this.disciplineTableBySemester : this.disciplineTableByCycleType)],
      this.state
    );
  }

  private filterData<T extends {isGroup?: boolean; semester?: number; dictCycleType?: string}>(input: {
    data: T[];
    semester?: number;
    cycleTypeId?: string;
  }) {
    return input.data.filter(
      (a) =>
        (input.semester ? a.semester === input.semester : a.dictCycleType === input.cycleTypeId) && !a.isGroup
    );
  }

  private handleGroupData<T extends DisciplineTable>(input: {data: T[], cycleTypeId?: string, semester?: number}) {
    const groupData = this.filterData(input);
    if (!groupData.length) {
      return {groupData: [], groupTotalValues: {laborIntensity: 0, hours: 0}};
    }

    const groupTotalValues = this.getTotalValues(groupData);
    return {filteredData: groupData, groupTotalValues: groupTotalValues};
  }

  private groupCycleTypes(data: DisciplineTableByCycleType[]) {
    const cycleTypes =  [...new Set(data.map(a => a.dictCycleType))];
    const groupData: DisciplineTableByCycleType[] = [];

    cycleTypes.forEach((item) => {
      const {filteredData, groupTotalValues} = this.handleGroupData({data: data, cycleTypeId: item});
      if (!filteredData?.length) {
        return;
      }

      groupData.push(...[
        {
          ...new DisciplineTableByCycleType(),
          name: item,
          laborIntensity: groupTotalValues.laborIntensity,
          hours: groupTotalValues.hours,
          isGroup: true
        }, ...filteredData]);
    });

    this.fillRowNumbers(groupData);
    const totalValues = this.getTotalValues(groupData);

    // TODO: Итого. Необходимо выводить перед факультативами. Не суммировать факультативы и элективную физру
    groupData.push({
      ...new DisciplineTableByCycleType(),
      laborIntensity: totalValues.laborIntensity,
      hours: totalValues.hours,
      name: 'Итого',
      isGroup: true
    });
    return groupData;
  }

  private groupSemesters(data: DisciplineTableBySemester[]) {
    const groupData: DisciplineTableBySemester[] = [];
    this.semesters.forEach((item) => {
      const {filteredData, groupTotalValues} = this.handleGroupData({data: data, semester: item.id});
      if (!filteredData?.length) {
        return;
      }

      groupData.push(...[
        {
          ...new DisciplineTableBySemester(),
          name: item.name,
          laborIntensity: groupTotalValues.laborIntensity,
          hours: groupTotalValues.hours,
          isGroup: true
        }, ...filteredData]);

    });

    this.fillRowNumbers(groupData);

    return groupData;
  }

  private fillRowNumbers<T extends DisciplineTable>(data: T[]) {
    data.filter(a => !a.isGroup).map((item, index) => {
      item.number = ++index;
    });
  }

  private createGrouping(data: unknown[]) {
    if (this.isSemester) {
      return this.groupSemesters(data as DisciplineTableBySemester[]);
    } else {
      return this.groupCycleTypes(data as DisciplineTableByCycleType[]);
    }
  }

  private getTotalValues<T extends DisciplineTable>(cycleData: T[]) {
    return cycleData.reduce((a, b) => ({
      hours: a.hours + b.hours,
      laborIntensity: a.laborIntensity + b.laborIntensity
    }), {hours: 0, laborIntensity: 0});
  }

  protected moveRow(index: number, up: boolean = false) {
    // TODO: Текущая реализация - заглушка. Будет передаваться запрос на сервер
    const item = this.gridView.data.splice(index, 1)[0];
    const rowIndex = up ? --index : ++index;
    this.gridView.data.splice(rowIndex, 0, item);
    this.gridView.data = this.createGrouping(this.gridView.data);
  }

}