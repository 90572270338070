// TODO: Предварительная версия
export class DisciplineTable {
  id = '';
  name = '';
  printName = '';
  hours = 0;
  dictMarkId? = '';
  sortNumber = 0;
  createdManually = false;
  laborIntensity = 0;
  isGroup? = false;
  number?: number;
  controlActionEnum?: number = 0;
}

export class DisciplineTableByCycleType extends DisciplineTable {
  dictCycleType = '';
  hasCourseWork = false;
  facultative = false;
  hoursContactWork = 0;
}

export class DisciplineTableBySemester extends DisciplineTable {
  semester = 0;
}

export class DisciplineTableFull extends DisciplineTableByCycleType {
  [key: string]: any;
  semester = 0;
}