import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../../../services/Notifications/notifications.service';
import {DownloadFile} from '../../../../helpers/downloadFile-helper';
import {ApplicationEditFormService} from '../../../../services/mfc/application-edit-form.service';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {KendoProperties} from '../../../../models/mfc/enums/kendo-properties.enum';
import {GroupProperty, Property} from '../../../../models/mfc/applicationConstructor/tabs-settings.model';
import {PropertiesService} from '../../../../services/mfc/properties.service';
import {GroupPropertyForm} from '../../../../models/mfc/applicationForm/application-form.model';
import {studentFieldsMap} from '../../../../models/mfc/student-fields';
import {ApplicationFormService} from '../../../../services/mfc/application-form.service';
import {saveAs} from '@progress/kendo-file-saver';
import {ExternalFile} from '../../../../models/mfc/applicationForm/application-property-value.model';
import {CostTableRow, StudentTableRow} from '../../../../models/mfc/applicationForm/tables.model';
import {DictPropertyEnum} from '../../../../models/mfc/enums/dict-property-enum';
import {AttachedFile} from '../../../../models/mfc/applicationForm/attachedfile.model';
import {AttachedFileService} from '../../../../services/mfc/attached-file.service';
import {getDateDiff} from '../../../../helpers/date-helper';
import {SpecializationService} from '../../../../services/mfc/dicts/specialization.service';

@Component({
  selector: 'mfc-dynamic',
  templateUrl: './dynamic-properties.component.html',
  styleUrls: ['./dynamic-properties.component.scss']
})
export class DynamicPropertiesComponent implements OnInit, OnDestroy {
  @Input() groups?: GroupProperty[] = [];
  @Input() editable = false;
  @Input() index = 0;
  @Input() isAdd = false;

  private hasChanges = false;
  protected editMode = false;
  protected specializations: string[] = [];

  protected readonly KendoProperties = KendoProperties;
  protected readonly DictProperty = DictPropertyEnum;
  protected readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  private allowedExtensions = ['.docx', '.doc', '.pdf', '.gif', '.jpg', '.png', '.tif', '.xls', '.xlsx'];
  protected readonly fileRestrictions = {
    allowedExtensions: this.allowedExtensions,
    accept: this.allowedExtensions.join(', '),
    maxCount: 10
  };

  private saveSubscription$!: Subscription;
  private checkChangesSubscription$!: Subscription;

  protected groupsFormGroup = this.createGroupFormGroup();
  protected readonly studentFieldsMap = studentFieldsMap;

  constructor(
    private cdRef: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private propertiesService: PropertiesService,
    private editFormService: ApplicationEditFormService,
    private applicationFormService: ApplicationFormService,
    private specializationService: SpecializationService,
    private attachedFileService: AttachedFileService
  ) { }

  ngOnInit(): void {
    this.getDicts();
    this.groupsFormGroup = this.createGroupFormGroup(this.groups);
    this.patchFormValues();
    this.subscribe();
  }

  private getDicts() {
    const hasSpecializationField = this.groups?.some((item) =>
      item.standardProperties.some((item) => item.dictPropertyEnum === DictPropertyEnum.Specialization));

    if (hasSpecializationField) {
      this.specializationService.getAll().subscribe((data) => {
        this.specializations = data;
        this.specializations.unshift('Не планирую', 'Нет в списке');
        this.cdRef.detectChanges();
      });
    }
  }

  private subscribe() {
    this.saveSubscription$ = this.editFormService.save$.subscribe(value => {
      if (this.index === value) {
        this.editFormService.isValid = this.groupsFormGroup.valid;
        this.editFormService.setHasChanges(this.hasChanges);
        this.saveChanges();
      }
    });
    this.checkChangesSubscription$ = this.editFormService.checkChanges$.subscribe(value => {
      if (this.index === value) {
        this.editFormService.setHasChanges(this.hasChanges);
      }
    });
  }

  private mapGroupFormArray(groups?: GroupProperty[]) {
    const formArr = new FormArray<FormGroup>([]);
    groups?.forEach((item) => {
      formArr.push(this.createFormGroup(item));
    });
    return formArr;
  }

  private createGroupFormGroup(groups?: GroupProperty[]) {
    return new FormGroup({
      groups: this.mapGroupFormArray(groups)
    });
  }

  private createFormGroup(group: GroupProperty) {
    const properties = [...group.standardProperties, ...group.additionalProperties];
    return new FormGroup({
      properties: this.mapFormArrayProperties(properties)
    });
  }

  private mapFormArrayProperties(properties: Property[]) {
    const formArr = new FormArray<FormGroup>([]);
    properties.forEach((item) => {
      formArr.push(this.patchFormArrayData(item));
    });
    return formArr;
  }

  private patchFormArrayData(item: Property) {
    const arr: { [key: string]: AbstractControl<unknown> } = {};
    Object.keys(item).forEach((key) => {
      arr[key] = new FormControl(item[key as keyof Property]);
    });

    const editable  = this.editable && this.getPropertyAccess(item);
    arr['propertyValue'] = new FormControl({
      value: item.value || item.defaultValue ? this.propertiesService.valueViewConverter(item, editable) : null,
      disabled: !editable
    }, (item.required ? Validators.required : null));

    arr['editable'] = new FormControl(editable);

    return new FormGroup(arr);
  }

  private getPropertyAccess(item: Property) {
    return item.isEditableForAll || item.isEditableForInitiator;
  }

  private patchFormValues() {
    const data = this.editFormService.getTabValues(this.index);
    if (data) {
      this.groupsFormGroup.controls.groups.controls.forEach((group) => {
        (<FormArray>group.controls['properties']).controls.forEach((property) => {
          const value = data.find((item) =>
            item.applicationConstructorPropertyId === property.value.externalId)?.value;
          property.get('propertyValue')?.patchValue(value ?? null);
        });
      });
    }
  }

  private saveChanges() {
    const formValues = this.propertiesService.mapFormValues(<GroupPropertyForm[]>this.groupsFormGroup.value.groups);
    this.editFormService.saveTabData(this.index, formValues);
  }

  protected formProperties(index: number) {
    return <FormArray>this.groupsFormGroup.get('groups')?.get(`${index}`)?.get('properties');
  }

  protected valueChange() {
    this.hasChanges = true;
  }

  protected dateRangeChange(value: {dateStart?: Date, dateEnd?: Date}|null, control: AbstractControl<unknown>) {
    control.patchValue({propertyValue: value});
    const isValid = (!control.get('required')?.value || value?.dateStart)
      && (value?.dateStart || !value?.dateEnd) && (!value?.dateStart || value?.dateEnd) && (value?.dateStart! <= value?.dateEnd!);
    control.setErrors(isValid ? null : { notCorrect: true });
    this.valueChange();
  }

  protected getDuration(value: {dateStart?: Date, dateEnd?: Date}|null) {
    return value?.dateStart && value?.dateEnd && (value.dateStart <= value.dateEnd) ? getDateDiff(value.dateStart, value.dateEnd, 'day') + 1 : 0;
  }

  protected fileChange(filesControl: AbstractControl|null, files?: File[]) {
    if (files?.length && files?.length > this.fileRestrictions.maxCount) {
      this.notificationsService.showError('Нельзя загрузить больше 10 файлов');
      if (filesControl) {
        filesControl.value.splice(this.fileRestrictions.maxCount, files.length - this.fileRestrictions.maxCount);
        filesControl.patchValue(filesControl.value);
      }
    }
    this.valueChange();
  }

  protected downloadFiles(files?: ExternalFile[]) {
    files?.forEach((file) => {
      if (file.id) {
        this.applicationFormService.downloadPropertyFile(file.id).subscribe((response) => {
          saveAs(response, file.name);
        });
      } else {
        DownloadFile(file);
      }
    });
  }

  private unsubscribe() {
    this.saveSubscription$.unsubscribe();
    this.checkChangesSubscription$.unsubscribe();
  }

  protected tableValueChanged(value: StudentTableRow[] | CostTableRow[], control: AbstractControl<unknown>) {
    const isValid = !(value.some((element) => Object.values(element).some(val => val == null || val === '')));
    control.patchValue({propertyValue: value});
    control.setErrors(isValid ? null : { notCorrect: true });
    this.valueChange();
  }

  protected downloadAttachedFile(file: AttachedFile) {
    this.attachedFileService.downloadFile(file.id)
      .subscribe((response) => saveAs(response, file.nameOnDisk));
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
