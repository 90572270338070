<div class="container">
  <ng-container *ngIf="isSemester && editable">
    <div class="field">
      <h3>Семестры</h3>
      <kendo-dropdownlist></kendo-dropdownlist>
    </div>
  </ng-container>

  <kendo-grid [data]="gridView"
              [height]="700"
              [rowHeight]="49"
              [skip]="state.skip"
              [pageSize]="state.take"
              (pageChange)="pageChange($event)"
              scrollable="virtual"
              class="grid-data">

    <kendo-grid-column [width]="50" title="№">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <ng-container *ngIf="!dataItem.isGroup">
          {{dataItem.number}}
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column class="left-align" field="name" title="Дисциплина">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
        <span [class]="{'group-header': dataItem.isGroup, 'total-field': dataItem.name === 'Итого'}">
          {{dataItem[column.field]}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="200" field="laborIntensity" title="Зач. ед.">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
        <span [class]="{semibold: dataItem.isGroup}">
          {{dataItem[column.field]}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="200" field="hours" title="Общее количество часов">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
        <span [class]="{semibold: dataItem.isGroup}">
          {{dataItem[column.field]}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="dictMarkId" title="Итоговая оценка">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
        <ng-container *ngIf="!dataItem.isGroup">
          {{dataItem[column.field]}}
        </ng-container>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>